import axios from "axios";

export default {
//authenticate user route
authenticateUser: function(userData) {
    return new Promise((resolve, reject) =>{
        axios.post("/api/user/login", userData)
        .then(response => {
            resolve(response)
        })
        .catch(error => {
            reject(Error("failed to login error: " + error))
        })
    })

},
//logout user
logoutUser: function(userData) {
    return new Promise((resolve, reject) =>{
        axios.post("/api/user/logout", userData)
        .then(response => {
            resolve(response)
        })
        .catch(error => {
            reject(Error("failed to login error: " + error))
        })
    })

},
//register user route
registerUser: function(regData) {
    return new Promise((resolve, reject) => {
    axios.post("/api/user/register", regData)
    .then(response=>{
        if(response.data){
            resolve(response)
        }
        else{
            console.log('logon error')
        }
    })
    .catch(error=>{
        reject(Error("failed to register error: " + error))
    })
})
},
//register user route
getUsers: function() {
    return new Promise((resolve, reject) => {
    axios.get("/api/user/findAll")
    .then(response=>{
        resolve(response)
    })
    .catch(error=>{
        reject(Error("error: " + error))
    })
})
},
//submit forum
submitForum: function(forumData){
    return new Promise((resolve, reject) => {
        axios.post("/api/forum/createForum", forumData)
    .then(response=>{
        resolve(response)
    })
    .catch(error=>{
        reject(Error(error))
    })
    })
},
//get forum
getForums: function(){
return new Promise((resolve, reject) => {
    axios.get("/api/forum/getForums")
.then(response=>{
    resolve(response)
})
.catch(error=>{
    reject(Error(error))
})
})
},
//delete forum
deleteForum: function(id){
    return new Promise((resolve, reject) => {
        axios.delete("/api/forum/deleteForum/" + id)
    
    .then(response=>{
        resolve(response)
    })
    .catch(error=>{
        reject(Error(error))
    })
})
},
//send text message
sendTextMessage: function(textData){
    return new Promise((resolve, reject) => {
        axios.post("/api/text/sendTextMessage/", textData)
    
    .then(response=>{
        resolve(response)
    })
    .catch(error=>{
        reject(Error(error))
    })
})
},
//send email
sendEmail: function(emailData){
    return new Promise((resolve, reject) => {
        axios.post("/api/email/sendEmail/", emailData)
    
    .then(response=>{
        resolve(response)
    })
    .catch(error=>{
        console.log(error)
        reject(Error(error))
    })
})
},
//send payment
sendPayment: function(paymentData){
    return new Promise((resolve, reject) => {
        axios.post("/api/payment/sendPayment/", paymentData)
        .then(response=>{
            resolve(response)
        })
        .catch(error=>{
           alert(error.response.data.message)
            reject(Error(error))
            
        })
    })
    },
submitFullRefund: function(paymentData){
      
        return new Promise((resolve, reject) => {
            axios.post("/api/payment/submitFullRefund/", paymentData)
            .then(response=>{
                resolve(response)
            })
            .catch(error=>{
               alert(error.response.data.message)
                reject(Error(error))
                
            })
        })
        },
//submit partial refund
submitPartialRefund: function(paymentData){

    return new Promise((resolve, reject) => {
        axios.post("/api/payment/submitPartialRefund/", paymentData)
        .then(response=>{
            resolve(response)
        })
        .catch(error=>{
            alert(error.response.data.message)
            reject(Error(error))
            
        })
    })
    },   
//submit order
submitOrder: function(orderData){
    return new Promise((resolve, reject) => {
        axios.post("/api/orders/createOrder/", orderData)
        .then(response=>{
            resolve(response)
        })
        .catch(error=>{
            console.log(error)
            reject(Error(error))
        })
    })
    },
    //get all orders
getOrders: function(){
    return new Promise((resolve, reject) => {
        axios.get("/api/orders/getOrder")
    .then(response=>{
        resolve(response)
    })
    .catch(error=>{
        reject(Error(error))
    })
    })
    },
    //get single order
getOrder: function(id){
        return new Promise((resolve, reject) => {
            axios.get("/api/orders/getOrder/" + id)
        .then(response=>{
            resolve(response)
        })
        .catch(error=>{
            reject(Error(error))
        })
        })
        },
getCompletedOrders: function(){
    return new Promise((resolve, reject) => {
        axios.get("/api/completedOrders/getCompletedOrders")
    .then(response=>{
        resolve(response)
    })
    .catch(error=>{
        reject(Error(error))
    })
    })
    },
//delete order
deleteOrder: function(id){
    return new Promise((resolve, reject) => {
        axios.delete("/api/orders/deleteOrder/" + id)
    
    .then(response=>{
        resolve(response)
    })
    .catch(error=>{
        reject(Error(error))
    })
})
},
completeOrder: function(orderData){
    return new Promise((resolve, reject) => {
        axios.post("/api/completedOrders/completeOrder/", orderData)
        .then(response=>{
            resolve(response)
        })
        .catch(error=>{
            console.log(error)
            reject(Error(error))
        })
    })
    },
//update complete order
updateCompleteOrder: function(id, status){   
    return new Promise((resolve, reject) => {
        axios.put("/api/completedOrders/updateCompleteOrder/" + id, status)
        .then(response=>{
            resolve(response)
        })
        .catch(error=>{
            console.log('THIS IS THE ERROR FROM REFUND' + error)
            reject(Error(error))
        })
    })
    },

//submitMenuItem
submitMenuItem: function(menuData){
    return new Promise((resolve, reject) => {
        axios.post("/api/menu/createMenu/", menuData)
        .then(response=>{
            resolve(response)
        })
        .catch(error=>{
            console.log(error)
            reject(Error(error))
        })
    })
    },
getMenu: function(){
    return new Promise((resolve, reject) => {
        axios.get("/api/menu/getMenu")
    .then(response=>{
        resolve(response)
    })
    .catch(error=>{
        reject(Error(error))
    })
    })
    },
//delete Menu Item
deleteMenuItem: function(id){
        return new Promise((resolve, reject) => {
            axios.delete("/api/menu/deleteMenu/" + id)
        
        .then(response=>{
            resolve(response)
        })
        .catch(error=>{
            reject(Error(error))
        })
    })
    },
//submitStoreItem
submitStoreItem: function(storeData){
    return new Promise((resolve, reject) => {
        axios.post("/api/store/createStore/", storeData)
        .then(response=>{
            resolve(response)
        })
        .catch(error=>{
            console.log(error)
            reject(Error(error))
        })
    })
    },
getStore: function(){
    return new Promise((resolve, reject) => {
        axios.get("/api/store/getStore")
    .then(response=>{
        resolve(response)
    })
    .catch(error=>{
        reject(Error(error))
    })
    })
    },
//delete Store Item
deleteStoreItem: function(id){
        return new Promise((resolve, reject) => {
            axios.delete("/api/store/deleteStore/" + id)
        
        .then(response=>{
            resolve(response)
        })
        .catch(error=>{
            reject(Error(error))
        })
    })
    },

submitReservation: function(reservationData){
        return new Promise((resolve, reject) => {
            axios.post("/api/reservations/createReservation", reservationData)
        .then(response=>{
            resolve(response)
        })
        .catch(error=>{
            reject(Error(error))
        })
        })
    },
getReservations: function(){
        return new Promise((resolve, reject) => {
            axios.get("/api/reservations/getReservations")
        .then(response=>{
            resolve(response)
        })
        .catch(error=>{
            reject(Error(error))
        })
        })
    }


};
