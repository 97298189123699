import React, { useState, useContext, useEffect} from 'react'
import PageCreator from '../PageCreator'
import chickenImage from '../../assets/images/chicken.jpg'
import { Button, Form, Row, Col, InputGroup, FormControl, Image, Modal, Container, Card, CardGroup} from 'react-bootstrap'
import '../../assets/scss/index.scss'
import { OrderContext } from '../../OrderContext';
import { StoreContext } from '../../StoreContext';
import history from '../History';
import API from '../../utils/API';
import { UserContext } from '../../UserContext';
import socketIOClient from 'socket.io-client';


export default function () {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const { isAdmin } = useContext(UserContext);
    const {storeInfo, setStoreInfo} = useContext(StoreContext);
    const {submittedOrder, setSubmittedOrder} = useContext(OrderContext);
    
    const endpoint = "http://localhost:3001";
    const socket = socketIOClient(endpoint);

    const [order, setOrder] = useState({});

    function getStoreInfo(){
        API.getStore().then(  
            function(response){
            setStoreInfo(response.data)  
          })        
    }

    const increaseMenuItemCount = event => {
        event.preventDefault();

        const targetID = event.target.id
        const targetName = event.target.name
        const targetPrice = event.target.value
        const targetDescription = event.target.getAttribute('description')

        //initialize object into order
        if(isNaN(order[targetName])){
        
            setOrder(prevState => ({...prevState, [targetName]:0}))
            setSubmittedOrder(prevState =>({...prevState, [targetID]:{title:targetName, quantity:0, cost:targetPrice, description:targetDescription, id:[targetID]}}))
        }
        setOrder(prevState => ({...prevState, [targetName]: prevState[targetName] + 1}))
        setSubmittedOrder(prevState =>({...prevState, [targetID]:{title:targetName, quantity: prevState[targetID].quantity + 1, cost:targetPrice, description:targetDescription, id:[targetID]}}))
    }

    const decreaseMenuItemCount = event => {
        event.preventDefault();
        const targetID = event.target.id
        const targetName = event.target.name
        const targetPrice = event.target.value
        const targetDescription = event.target.getAttribute('description')

        if(order[targetName] !== 0 && isNaN(order[targetName]) !== true){
        setOrder(prevState => ({...prevState, [targetName]: prevState[targetName] - 1}))
        setSubmittedOrder(prevState =>({...prevState, [targetID]:{title:targetName, quantity: prevState[targetID].quantity - 1, cost:targetPrice, description:targetDescription}}))
        }
    }

    const deleteStoreItem = (id) => {
        API.deleteStoreItem(id)
        .then(
            API.getStore().then(  
                function(response){
                setStoreInfo(response.data)  
                history.push('/Store')
            })
        )
    }

    const submitOrder = event =>{
        event.preventDefault();   
        history.push('/SubmitPayment')

    }

    socket.on('menuChangeEvent', async () =>{
        try{
        await API.getStore().then(
                function (response) {
                setStoreInfo(response.data)
                })
        }
        catch(error){
            console.log('THIS IS THE ERROR!', error)
        }
    })
    
    useEffect(() => {
        getStoreInfo()
       
    },[]);

    return (
        <PageCreator imageName={chickenImage} opacityLevel="0.9">
            <Form>
                <h1 className='mainFont'>Store</h1>
                <hr style={{ backgroundColor: '222222' }} />
                <Row>
                <CardGroup>
                {storeInfo.map(item => (
                    <div  key = {item._id} id={item._id}>
                    <Col xs={12} md={12} lg={12}>
                        <Card className='boxShadow' style={{borderStyle: 'none', margin: '10px'}}>
                        <Card.Img variant="top" src={item.storeImageURL} />
                        <Card.Body>
                            <Card.Title>{item.storeName}</Card.Title>
                            <Card.Text>
                            {item.storeDescription}
                            </Card.Text>
                            <Col lg={6} md={6} sm={6} xs={6}>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <Button id={item._id} name={item.storeName} value={item.storeItemPrice} description={item.storeDescription} onClick={decreaseMenuItemCount} variant="outline-secondary">-</Button>
                                </InputGroup.Prepend>
                                <FormControl style={{textAlign:'right'}} name={item.storeName} placeholder='0' aria-describedby="basic-addon1" value={order[item.storeName]} />
                                
                                <InputGroup.Append>
                                    <Button variant="outline-secondary" id={item._id} name={item.storeName} value={item.storeItemPrice} description={item.storeDescription} onClick={increaseMenuItemCount}>+</Button>
                                </InputGroup.Append>
                            </InputGroup>
                            </Col>
                        </Card.Body>
                        </Card>
                 
                    </Col>
                  </div>
                ))}
                </CardGroup>
                </Row>
                <Button onClick={submitOrder} style={{float:'right', marginTop:'5px'}} variant='outline-dark' size='sm'>Add to Take Out Order</Button>
            </Form>
        </PageCreator>
    )
}
