import React, {useContext} from 'react'
import { Link, useHistory } from 'react-router-dom';
import API from '../../utils/API'
import { Button, Navbar, Nav, NavDropdown, Badge } from 'react-bootstrap';
import { UserContext } from '../../UserContext';
import pajuImage from '../../assets/images/paju_sign.jpg'
import { OrderContext } from '../../OrderContext';


export default function MainNavbar() {

    //Used for Login
    const { isLoggedIn, setLoggedIn } = useContext(UserContext);
    const { isAdmin, setIsAdmin } = useContext(UserContext);
    const history = useHistory();

    const logout = event => {
        event.preventDefault();
        API.logoutUser();
        setLoggedIn(false);
        setIsAdmin(false);
        history.push('/SignOut')
    }
    //for Menu Order

    const { submittedOrder } = useContext(OrderContext);
    const sumTotalCount = Object.values(submittedOrder).reduce((total, { quantity }) => total + quantity, 0)

    return (

        <div>
            <Navbar style={{minHeight: "5vh"}}bg="#ffffff" variant="light" collapseOnSelect expand="lg">
                <Navbar.Brand as={Link} to="/"><img alt='paju symbol' src={pajuImage} style={{height:'5vh'}}/></Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse>
                <Nav className="mr-auto">
                    <Nav.Link as={Link} to="/">Home</Nav.Link>
                    <Nav.Link as={Link} to="/About">Story</Nav.Link>
                    {/* <Nav.Link as={Link} to="/PopUp">Pop Up</Nav.Link> */}
                    <Nav.Link as={Link} to="/Menu">Menu</Nav.Link>
                    {/* <Nav.Link as={Link} to="/Store">Store</Nav.Link> */}
                    <Nav.Link as={Link} to="/Reservations">Reservations</Nav.Link>
                   

                    <Nav.Link as={Link} to="/Location">Location</Nav.Link>
                    <Nav.Link as={Link} to="/Gallery">Gallery</Nav.Link>
                    <Nav.Link as={Link} to="/Forum">Blog</Nav.Link>
                    <Nav.Link as={Link} to="/Accolades">Accolades</Nav.Link>
                    {isLoggedIn ? <NavDropdown bg="light" title="Tools" id="collapsible-nav-dropdown">
                        
                        {isAdmin? <NavDropdown.Item style={{ color: "#000000" }} as={Link} to="/Orders">Orders</NavDropdown.Item> : ''}
                        {isAdmin? <NavDropdown.Item style={{ color: "#000000" }} as={Link} to="/MenuCreator">Menu Creator</NavDropdown.Item> : ''}
                        {isAdmin? <NavDropdown.Item style={{ color: "#000000" }} as={Link} to="/StoreCreator">Store Creator</NavDropdown.Item> : ''}
                        {isAdmin? <NavDropdown.Item style={{ color: "#000000" }} as={Link} to="/CompletedOrders">Completed Orders</NavDropdown.Item> : ''}
                        {isAdmin? <NavDropdown.Item style={{ color: "#000000" }} as={Link} to="/ReservationManagement">Reservation Management</NavDropdown.Item> : ''}
                        {isAdmin? <NavDropdown.Item style={{ color: "#000000" }} as={Link} to="/SubmitForum">Submit Forum</NavDropdown.Item> : ''}
                        {isAdmin? <NavDropdown.Item style={{ color: "#000000" }} as={Link} to="/SubmitTextMessage">Submit Text Message</NavDropdown.Item> : ''}
                        {isAdmin? <NavDropdown.Item style={{ color: "#000000" }} as={Link} to="/SubmitEmail">Submit Email</NavDropdown.Item> : ''}

                    </NavDropdown>: ""}
                  
                </Nav>
                <br/>
                {/* <Button variant="outline-dark" as={Link} to="/SubmitPayment"><i className='fas fa-box'></i> &nbsp;Take Out Order&nbsp;<Badge style={{color: '#ffffff'}} variant="danger">{sumTotalCount === 0 ? '': sumTotalCount}</Badge></Button>&nbsp; */}
                {isLoggedIn ? <Button variant="outline-dark" onClick={logout}>Logout</Button>: ''}
                </Navbar.Collapse>
            </Navbar>
            
        </div>
    )
}
