import React, { useState, useContext } from 'react'
import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js"
import { Button, Form, Container, Jumbotron, Col, Table, Spinner } from 'react-bootstrap'
import API from '../../utils/API'
import history from '../History';
import { OrderContext } from '../../OrderContext';


const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    // const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

    //order details
    const { submittedOrder, setSubmittedOrder } = useContext(OrderContext);
    const sumTotalCount = Object.values(submittedOrder).reduce((total, { quantity }) => total + quantity, 0)
    const sumTempTotalCost = Object.keys(submittedOrder).map((item, total) => (
        total = (submittedOrder[item].quantity * submittedOrder[item].cost)
    ))
    const sumTotalCostCalc = sumTempTotalCost.reduce((a, b) => a + b, 0)
    const sumTotalCost = (Math.round((sumTotalCostCalc) * 100) / 100).toFixed(2);
    const sumTotalTax = (Math.round((sumTotalCost * 0.1025) * 100) / 100).toFixed(2);

    const [tip, setTip] = useState('0.00');

    const total = (Math.round((parseFloat(sumTotalCost) + parseFloat(sumTotalTax) + parseFloat(tip)) * 100) / 100).toFixed(2);

    //billing details
    const totalStripe = parseInt(((Math.round((parseFloat(sumTotalCost) + parseFloat(sumTotalTax) + parseFloat(tip)) * 100) / 100).toFixed(2)) * 100);

    // const [city, setCity] = useState('');
    // const [line1, setLine1] = useState('');
    // const [line2, setLine2] = useState('');
    // const [postal_code, setPostalCode] = useState('');
    // const [state, setState] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [pickUpTime, setPickUpTime] = useState('within 15 minutes');
 


    const [tempDescription, setTempDescription] =  useState('')

    const[phoneErrorMessage, setPhoneErrorMessage] = useState('')
    const[emailErrorMessage, setEmailErrorMessage] = useState('')

    const[paymentID, setPaymentID] = useState('')

    const [loading, setLoading] = useState(false);

    //remove item from order
    const removeItem = (event) => {
        event.preventDefault()
        delete submittedOrder[event.target.value]
        setSubmittedOrder(submittedOrder)
        //to reload after removal of item
        history.push('/SubmitPayment')
    }
    //handle submission for payment
    const handleSubmit = async (event) => {
        event.preventDefault();

        var phoneCheck = false;
        var emailCheck = false;
        
        const phonePattern = /^\+?[0-9_-]{10}$/g;
        const phonePattern2 = /^\+?[0-9_-]{11}$/g;
        const phonePattern3 = /((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}/g;
        const emailPattern = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/g
    


        if(phone.match(phonePattern) || phone.match(phonePattern2) || phone.match(phonePattern3)){
            setPhoneErrorMessage('')
            phoneCheck = true;
        }
        else{
            setPhoneErrorMessage('please use valid phone number')
        }
        if(email.match(emailPattern)){
            setEmailErrorMessage('')
            emailCheck = true;
        }
        else{
            setEmailErrorMessage('please use valid email')
        }

        if(phoneCheck === true && emailCheck === true ){
        
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
            billing_details: {
                email: email,
                name: firstName + " " + lastName
            }
        });
        let current_datetime = new Date(Date.now())
        let formatted_date = current_datetime.toLocaleString('en-US', { timeZone: 'America/Los_Angeles' })

        let emailText =   Object.keys(submittedOrder).map((item) => {
            return `
            <h3>${submittedOrder[item].title}</h3>
            <p> ${submittedOrder[item].description}</p>
            <p>Quantity Ordered: ${submittedOrder[item].quantity}</p>
            <p>Cost: $${Math.round((((submittedOrder[item].cost)*(submittedOrder[item].quantity))*100)/100).toFixed(2)}</p>`
        })
        
        let textText =   Object.keys(submittedOrder).map((item) => {
            return `\n${submittedOrder[item].title}\n- Quantity Ordered: ${submittedOrder[item].quantity}\n- Cost: $${Math.round((((submittedOrder[item].cost)*(submittedOrder[item].quantity))*100)/100).toFixed(2)}\n`
        })
        if (!error) {
            const { id } = paymentMethod
            
            try {
                setLoading(true)
                // const { data } = 
                await API.sendPayment({
                    id,
                    amount: totalStripe,
                }).then(response => {
                    setPaymentID(response.data.paymentID)
                    API.submitOrder({
                        name: firstName + " " + lastName,
                        email: email,
                        phone: phone,
                        date: formatted_date,
                        pickUpTime: pickUpTime,
                        order: submittedOrder,
                        total: total,
                        status: 'paid',
                        paymentID: response.data.paymentID
                    })
                    
                })
                setSubmittedOrder({})

            
               

                Object.keys(submittedOrder).map((item, i) => (
                    setTempDescription(submittedOrder[item].description)
                ))

                API.sendEmail({
                    email: email,
                    title: "Thank you for your payment",
                    emailText: `Thank you for your purchase ${firstName + ' ' + lastName}`,
                    htmlText:`
                    <h1>Paju Order</h1><hr />
                    <h2>Thank you ${firstName + ' ' + lastName} for your purchase.</h2>
                    <p>Following are your order details:</p>
                    <b>Pick Up Time: ${pickUpTime}</b>
                    ${emailText} <br/>
                    <hr/>
                    <p>Sub Total Cost: $ ${sumTotalCost}</p>
                    <p>Tip: $ ${tip}</p>
                    <p>Tax: $ ${sumTotalTax}</p>
                    <b>Total Cost: $ ${total}</b>`

                })

                API.sendTextMessage({
                    recipient: phone,
                    text: `
                    Paju: Thank you ${firstName + ' ' + lastName} for your purchase. Following are your order details:\n\n- Pick Up Time: ${pickUpTime}\n${textText}\n\nSub Total Cost: $ ${sumTotalCost}\nTip: $ ${tip}\nTax: $ ${sumTotalTax}\nTotal Cost: $ ${total}`
                })
                setLoading(false)
                history.push('/ThankYou')

            }
            catch (error) {
                setLoading(false)
                console.log('THIS IS THE ERROR!', error)
            }
        }
    }
    }

    return (
        <Jumbotron fluid>
            <Container>
                <Jumbotron style={{ backgroundColor: '#FFFFFF' }}>
                    <p>Order</p>

                    <Table>
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th style={{ textAlign: 'center' }}>Quantity</th>
                                <th style={{ textAlign: 'center' }}>Item Price</th>
                                <th style={{ textAlign: 'center' }}>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(submittedOrder).map((item, i) => (
                                <>
                                 {submittedOrder[item].quantity > 0 ?  
                                 <tr>
                                    <td>
                                        <p className='mainFont' style={{ marginBottom: '0px' }}>{submittedOrder[item].title}&nbsp;&nbsp;&nbsp;</p>
                                        <p style={{ fontSize: '0.85em' }}>{submittedOrder[item].description}</p>
                                        <p></p>
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                        <p className='mainFont'>{submittedOrder[item].quantity}</p>
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                        <p className='mainFont'>${submittedOrder[item].cost}</p>
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                        <p className='mainFont'>${submittedOrder[item].cost * submittedOrder[item].quantity}</p>
                                    </td>
                                
                                    <td>
                                    <Button value={submittedOrder[item].id} variant='outline-danger' size='sm' onClick={removeItem}>X</Button>
                                    </td>
                                </tr>
                                : ''}
                               
                                </>
                            ))}
                            {sumTotalCount >= 1 ?
                                <tr style={{ borderBottom: '5px white solid', marginBottom: '0' }}>
                                    <td style={{ paddingBottom: '0px' }}>
                                    </td>
                                    <td style={{ paddingBottom: '0px' }}>
                                    </td>
                                    <td style={{ textAlign: 'center', paddingBottom: '0px' }}>
                                        <p className='mainFont' >Sub Total</p>
                                    </td>
                                    <td style={{ textAlign: 'center', paddingBottom: '0px' }}>
                                        <p className='mainFont'>${sumTotalCost}</p>
                                    </td>
                                </tr>
                                :
                                ''
                            }
                            {sumTotalCount >= 1 ?
                                <tr style={{ borderBottom: '5px white solid', marginBottom: '0' }}>
                                    <td style={{ paddingBottom: '0px' }}>
                                    </td>
                                    <td style={{ paddingBottom: '0px' }}>
                                    </td>
                                    <td style={{ textAlign: 'center', paddingBottom: '0px' }}>
                                        <p className='mainFont' >Tax</p>
                                    </td>
                                    <td style={{ textAlign: 'center', paddingBottom: '0px' }}>
                                        <p className='mainFont'>${sumTotalTax}</p>
                                    </td>
                                </tr>
                                :
                                ''
                            }
                              {sumTotalCount >= 1 ?
                                <tr style={{ borderBottom: '5px white solid', marginBottom: '0' }}>
                                    <td style={{ paddingBottom: '0px' }}>
                                    </td>
                                    <td style={{ paddingBottom: '0px' }}>
                                    </td>
                                    <td style={{ textAlign: 'center', paddingBottom: '0px' }}>
                                        <p className='mainFont' >Tip</p>
                                    </td>
                                    <td style={{ textAlign: 'center', paddingBottom: '0px' }}>
                                    <Form>
                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                            <Form.Control style={{textAlign: 'right', width: '100px'}} placeholder="$0.00" type="number" pattern="^\d*(\.\d{2}$)?" onChange={event => setTip(event.target.value)}>
                                            </Form.Control>
                                        </Form.Group>
                                    </Form>
                                    </td>
                                </tr>
                                :
                                ''
                            }
                            {sumTotalCount >= 1 ?
                                <tr style={{ borderBottom: '5px white solid', marginBottom: '0' }}>
                                    <td style={{ paddingBottom: '0px' }}>
                                    </td>
                                    <td style={{ paddingBottom: '0px' }}>
                                    </td>
                                    <td style={{ textAlign: 'center', paddingBottom: '0px' }}>
                                        <p className='mainFont' >Total</p>
                                    </td>
                                    <td style={{ textAlign: 'center', paddingBottom: '0px' }}>
                                        <p className='mainFont'>${total}</p>
                                    </td>
                                </tr>
                                :
                                ''
                            }
                          
                        </tbody>
                    </Table>
                </Jumbotron>
                <p className='mainFont' style={{ fontSize: '1.2em' }}>Schedule</p>
                <hr />
                <Form>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label>Time to Pick Up <p style={{fontSize: '0.7em'}}>Pick up must be for same day. We will do our best to have your order ready within 15 minutes. For large orders and pick up times other than order date please call ahead.</p></Form.Label>
                        <Form.Control as="select" onChange={event => setPickUpTime(event.target.value)}>
                            <option>Within 15 Minutes</option>
                            <option>4:00PM</option>
                            <option>4:30PM</option>
                            <option>5:00PM</option>
                            <option>5:30PM</option>
                            <option>6:00PM</option>
                            <option>6:30PM</option>
                            <option>7:00PM</option>
                            <option>7:30PM</option>
                        </Form.Control>
                    </Form.Group>
                </Form>
                {sumTotalCount >= 1 ?
                    <Form onSubmit={handleSubmit}>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridPassword">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control required type="firstName" placeholder="First Name" onChange={event => setFirstName(event.target.value)} />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridPassword">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control required type="lastName" placeholder="Last Name" onChange={event => setLastName(event.target.value)} />
                            </Form.Group>

                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control required value={phone} type="tel" placeholder="206-555-5555" onChange={event => setPhone(event.target.value)} />
                                <p style={{ color: "#FF0000", fontSize: "0.7em" }}> &nbsp;&nbsp;{phoneErrorMessage}</p>
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control required value={email} type="email" placeholder="example@example.com" onChange={event => setEmail(event.target.value)} />
                                <p style={{ color: "#FF0000", fontSize: "0.7em" }}> &nbsp;&nbsp;{emailErrorMessage}</p>
                            </Form.Group>
                        </Form.Row>
                        <div className="form-group">
                            <label htmlFor="card-element">Payment Information</label>
                            <div id="card-element" required={true} className="form-control" style={{ height: "2.4em", paddingTop: "0.7em" }}>
                                <CardElement />
                            </div>
                        </div>
                        <br />
                        <Button variant='outline-dark' style={{ float: 'right' }} type="submit" disabled={loading}>
                            {loading ?  <Spinner animation="border" /> : 'Checkout'}
                        </Button>
                        <br />
                        <br />
                    </Form>
                    :
                    ''
                }
            </Container>
        </Jumbotron>
    )
}

export default function index() {

    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
    return (
        <Elements stripe={stripePromise}>
            <CheckoutForm />
        </Elements>
    )
}
