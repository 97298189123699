import React, {useState} from 'react'
import {Table, Form, Button} from 'react-bootstrap'
import API from '../../utils/API'

export default function CompleteOrders({orders}) {

    const [refundAmount, setRefundAmount] = useState(0);

    const stripeRefundAmount= parseInt(((Math.round(parseFloat(refundAmount) * 100) / 100).toFixed(2)) * 100)
    const submitFullRefund = async (event, id, eventID) => {
        event.preventDefault()
    
        API.updateCompleteOrder(eventID, {
            status: 'refunded'
        })
        API.submitFullRefund({
            paymentID: id
        })
     

    }   
    const submitPartialRefund = async (event, id, eventID) => {
        event.preventDefault()
    
        API.updateCompleteOrder(eventID, {
        
            status: 'refunded'
        })
        API.submitPartialRefund({
            amount: stripeRefundAmount,
            paymentID: id
        })
     

    }   
    return (
        
        <Table striped bordered style={{fontSize: '0.75em'}} size='sm'>
        <thead>
        <tr>
            <th>
                #
            </th>
            <th>
                Name
            </th>
            <th>
                Email
            </th>
            <th>
                Phone
            </th>
            <th>
                Order Date and Time
            </th>
            <th>
                Pick Up Time
            </th>
            <th>
                Order
            </th>
            <th>
                Total
            </th>
            <th>
                Status
            </th>
            <th>
                Refund
            </th>
        </tr>
        </thead>
        <tbody>
        {orders.map((item, i) => {
        i=i+1;
     
        return(
            <tr key={item._id}>
                <td>
                    {i}
                </td>
                <td>
                    {item.name}
                </td>
                <td>
                    {item.email}
                </td>
                <td>
                    {item.phone}
                </td>
                <td>
                    {item.date}
                </td>
                <td>
                    {item.pickUpTime}
                </td>
                <td>
                
                {Object.keys(item.order).map((sub, i)=>(
                    
                      <p value={sub} key={i}>{item.order[sub].title} - {item.order[sub].quantity}</p>
                  
                   ))}
               
                </td>
                <td>
                    ${item.total}
                </td>
                <td>
                    {item.status}
                </td>
                <td>
                {(item.status!=='refunded') ?
                <> 
                <Button size='sm' variant="outline-danger" style={{marginTop: '5px'}} onClick={(event)=> submitFullRefund(event, item.paymentID, item._id)}>Full Refund</Button>&nbsp;<Button size='sm' variant="outline-danger" style={{marginTop: '5px'}} onClick={(event)=> submitPartialRefund(event, item.paymentID, item._id)}>Partial Refund</Button>  
                <br /><br />
                <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>Partial Refund Amount</Form.Label>
                <Form.Control size='sm' type="number" placeholder="$1.00" onChange={event => setRefundAmount(event.target.value)}/>
                </Form.Group>
                </>
                : ''}
                         
                </td>
            </tr>


        )})}
        </tbody>
    </Table>
    )
}
