import React from 'react'
import { Jumbotron, Container } from 'react-bootstrap'

export default function index() {
    return (
        <Jumbotron fluid>
            <Container>
            <h1>
            Thank you for your reservation
            </h1>

            </Container>


        </Jumbotron>
     
    )
}
