import React from 'react'
import {Container, Jumbotron, Row, Col} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import '../../assets/scss/index.scss'
import pajuSymbol from '../../assets/images/paju_symbol.jpg'

export default function index() {

    //<Link className='footerFont' style={{color:'#000000', fontSize: '0.85em', margin: '0'}} to="/Reservations">Reservations</Link>
    return (
        <Container>
            <Jumbotron style={{backgroundColor:'#ffffff', padding: '10px', height:'8vh'}}>
            <Row>
            <Col className='footerAlign' lg={3} sm={3} xs={12}>
                <Link className='footerFont' style={{color:'#000000', fontSize: '0.85em', margin: '0'}} to="/About">Story</Link><br />
                <Link className='footerFont' style={{color:'#000000', fontSize: '0.85em', margin: '0'}} to="/PopUp">Pop Up</Link><br />
                <Link className='footerFont' style={{color:'#000000', fontSize: '0.85em', margin: '0'}} to="/Location">Location</Link><br />
                <Link className='footerFont' style={{color:'#000000', fontSize: '0.85em', margin: '0'}} to="/Forum">Blog</Link><br />
                
                
            </Col>
            <Col className='footerAlign' lg={3} sm={3} xs={12}>
            <Link className='footerFont' style={{color:'#000000', fontSize: '0.85em', }} to="/Menu">Menu</Link><br />
            <Link className='footerFont' style={{color:'#000000', fontSize: '0.85em', }} to="/Gallery">Gallery</Link><br />
            <Link className='footerFont' style={{color:'#000000', fontSize: '0.85em', margin: '0'}} to="/Accolades">Accolades</Link><br />
            <Link className='footerFont' style={{color:'#000000', fontSize: '0.85em', margin: '0'}} to="/Reservations">Reservations</Link>
            </Col>
            <Col className='footerAlign' lg={3} sm={3} xs={12}>
                <div style={{margin: '10px 10px 30px 0px'}}><a href='mailto:jeong.bill@pajuseattle.com'><i style={{fontSize: '1.25em', color:'#999999', float: 'center', marginRight: '10px'}} className="far fa-envelope"></i></a><a target='_blank' rel="noopener noreferrer" href='https://www.facebook.com/paju.seattle/'><i style={{color:'#999999', float: 'center', marginRight:'10px', fontSize: '1.25em'}} className="fab fa-facebook-square fa-2x"></i></a><a target='_blank' rel="noopener noreferrer" href='https://www.instagram.com/paju_seattle/'><i style={{fontSize: '1.25em', color:'#999999', float: 'center', marginRight: '10px'}} className="fab fa-instagram"></i></a></div><br/>
            </Col>
            <Col className='footerAlign' lg={3} sm={3} xs={12}>
                <p className='footerFont' style={{marginBottom: '0'}}><img alt='paju symbol' src={pajuSymbol} style={{height:'3vh'}}/>&nbsp;&nbsp;Paju</p>
                <hr style={{margin:'5px'}}/>
                <p className='footerFont' style={{paddingTop: '0', marginBottom: '0', fontSize: '0.85em'}}><a target='_blank' rel="noopener noreferrer" href='https://maps.app.goo.gl/nHCi4dGew8Lhg6YU7' style={{color: '#000000', fontSize: '0.85em', textDecoration: 'none'}}>513 Westlake Avenue North Seattle, WA 98109</a></p>
                <p className='footerFont'><a style={{color: '#000000', fontSize: '1em', textDecoration: 'none'}} href='tel:206-829-8215'>(206) 829-8215</a></p>
                <p className='footerFont' style={{color: '#000000',  marginBottom: '0', fontSize: '0.75em'}}>Restaurant Hours:</p>
                <p className='footerFont' style={{color: '#000000', fontSize: '0.75em', marginTop: '0', marginBottom: '0'}}>Sunday Closed</p>
                <p className='footerFont' style={{color: '#000000', fontSize: '0.75em', marginTop: '0', marginBottom: '0'}}>Monday-Thursday 4:00PM-9:00PM</p>
                <p className='footerFont' style={{color: '#000000', fontSize: '0.75em', margintTop: '0', marginBottom: '0'}}>Friday-Saturday 4:00PM-10:00PM</p>
                {/* <p className='footerFont' style={{color: '#000000', fontSize: '0.75em'}}>Sunday 5:00PM-9:00PM</p> */}
                {/* <p className='footerFont' style={{color: '#000000', fontSize: '0.75em'}}>Please make your reservations <Link to="/Reservations">here</Link></p> */}


            </Col>
            </Row>
            </Jumbotron>
        </Container>
    )
}
