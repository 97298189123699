import React from 'react'
import rightChevron from '../../assets/images/gallery/RightChevron.svg'
import leftChevron from '../../assets/images/gallery/LeftChevron.svg'
import { FadeOnUpChar } from 'nehoa'
import testImage from '../../assets/images/gallery/TestImage.jpg'
import saladImage from '../../assets/images/gallery/Salad.jpg'
import chickenImage from '../../assets/images/gallery/chicken.jpg'
import friedRiceImage from '../../assets/images/gallery/FriedRice.jpg'
import bulgogiImage from '../../assets/images/gallery/bulgogiImage.jpg'
import kimchiImage from '../../assets/images/gallery/kimchee.jpg'
import pancakeImage from '../../assets/images/gallery/Fried_Pancake.jpg'
import saladDressing from '../../assets/images/gallery/Salad_Dressing.jpg'
import '../../components/Gallery/gallery.scss'
import '../../assets/scss/index.scss'

import { Carousel } from 'react-bootstrap'

export default function index() {


  return (
    <div style={{padding:'150px', textAlign: 'center', fontSize: '2rem'}}>

    We will be updating our gallery soon!
    </div>
    // <Carousel nextIcon={<img src={rightChevron} />} prevIcon={<img src={leftChevron} />}>
    //   <Carousel.Item>
    //     <div style={{ background: `url(${testImage}) fixed center center`, backgroundSize: 'cover', minHeight: '85vh' }}>
    //       <div className='galleryPosition'>
    //         <FadeOnUpChar className='mainFont' text='Spicy | Pork' style={{ color: '#444444', fontSize: '1.35em' }} />
    //         <br />
    //         <FadeOnUpChar className='mainFont' text='Cilantro, Poblano Peppers' style={{ color: '#444444', fontSize: '0.65em' }} />
    //       </div>
    //     </div>
    //   </Carousel.Item>
    //   <Carousel.Item>
    //     <div style={{ background: `url(${saladImage}) fixed center center`, backgroundSize: 'cover', minHeight: '85vh' }}>
    //       <div className='galleryPosition'>
    //         <FadeOnUpChar className='mainFont' text='Romaine | Salad' style={{ color: '#444444', fontSize: '1.35em' }} />
    //         <br />
    //         <FadeOnUpChar className='mainFont' text='Parmesan Cheese, Walnuts, Grapes, Smoked Vinaigrette' style={{ color: '#444444', fontSize: '0.65em' }} />
    //       </div>
    //     </div>
    //   </Carousel.Item>
    //   <Carousel.Item>
    //     <div style={{ background: `url(${chickenImage}) fixed center center`, backgroundSize: 'cover', minHeight: '85vh' }}>
    //       <div className='galleryPosition'>
    //         <FadeOnUpChar className='mainFont' text='Chicken | Wings' style={{ color: '#444444', fontSize: '1.35em' }} />
    //         <br />
    //         <FadeOnUpChar className='mainFont' text='Soy Garlic and Korean Sweet Chili' style={{ color: '#444444', fontSize: '0.65em' }} />
    //       </div>
    //     </div>
    //   </Carousel.Item>
    //   <Carousel.Item>
    //     <div style={{ background: `url(${friedRiceImage}) fixed center center`, backgroundSize: 'cover', minHeight: '85vh' }}>
    //       <div className='galleryPosition'>
    //         <FadeOnUpChar className='mainFont' text='Paju | Fried Rice' style={{ color: '#444444', fontSize: '1.35em' }} />
    //         <br />
    //         <FadeOnUpChar className='mainFont' text='Kimchi, Bacon, Squid Ink, Smoked Quail Egg' style={{ color: '#444444', fontSize: '0.65em' }} />
    //       </div>
    //     </div>
    //   </Carousel.Item>
    //   <Carousel.Item>
    //     <div style={{ background: `url(${bulgogiImage}) fixed center center`, backgroundSize: 'cover', minHeight: '85vh' }}>
    //       <div className='galleryPosition'>
    //         <FadeOnUpChar className='mainFont' text='Rib-eye | Steak Bulgogi' style={{ color: '#444444', fontSize: '1.35em' }} />
    //         <br />
    //         <FadeOnUpChar className='mainFont' text='Scallion, Crimini Mushroom, Rice Puff' style={{ color: '#444444', fontSize: '0.65em' }} />
    //       </div>
    //     </div>
    //   </Carousel.Item>
    //   <Carousel.Item>
    //     <div style={{ background: `url(${kimchiImage}) fixed center center`, backgroundSize: 'cover', minHeight: '85vh' }}>
    //       <div className='galleryPosition'>
    //         <FadeOnUpChar className='mainFont' text='Kimchi | 12oz' style={{ color: '#444444', fontSize: '1.35em' }} />
    //         <br />
    //         <FadeOnUpChar className='mainFont' text='Napa cabbage' style={{ color: '#444444', fontSize: '0.65em' }} />
    //       </div>
    //     </div>
    //   </Carousel.Item>
    //   <Carousel.Item>
    //     <div style={{ background: `url(${pancakeImage}) fixed center center`, backgroundSize: 'cover', minHeight: '85vh' }}>
    //       <div className='galleryPosition'>
    //         <FadeOnUpChar className='mainFont' text='Paju | Crispy Pancake' style={{ color: '#444444', fontSize: '1.35em' }} />
    //         <br />
    //         <FadeOnUpChar className='mainFont' text='Seafood Mix, Perilla Leaves, Bonito Flakes' style={{ color: '#444444', fontSize: '0.65em' }} />
    //       </div>
    //     </div>
    //   </Carousel.Item>
    //   <Carousel.Item>
    //     <div style={{ background: `url(${saladDressing}) fixed center center`, backgroundSize: 'cover', minHeight: '85vh' }}>
    //       <div className='galleryPosition'>
    //         <FadeOnUpChar className='mainFont' text='Smoked | Vinaigrette (8oz)' style={{ color: '#444444', fontSize: '1.35em' }} />
    //         <br />
    //         <FadeOnUpChar className='mainFont' text='Be aware (too good)' style={{ color: '#444444', fontSize: '0.75em' }} />
    //       </div>
    //     </div>
    //   </Carousel.Item>
    // </Carousel>

  )
}
