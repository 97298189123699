import React from 'react'
import specialImage from '../../assets/images/edited2.png'
import { Jumbotron } from 'react-bootstrap'
import '../../assets/scss/index.scss'
import PageCreator from '../../components/PageCreator'
import { FadeOnUp } from 'nehoa'


import rightChevron from '../../assets/images/gallery/RightChevron.svg'
import leftChevron from '../../assets/images/gallery/LeftChevron.svg'
import { FadeOnUpChar } from 'nehoa'
import oImage1 from '../../assets/images/specials/O_IMG_1_2.jpg'
import oImage2 from '../../assets/images/specials/O_IMG_1_4.jpg'
import oImage3 from '../../assets/images/specials/O_IMG_1_3.jpg'
import oImage4 from '../../assets/images/specials/O_IMG_1_5.jpg'
import oImage5 from '../../assets/images/specials/O_IMG_1_1.jpg'
import oImage6 from '../../assets/images/specials/O_IMG_1_6.jpg'


import '../../components/Special/special_gallery.scss'
import '../../assets/scss/index.scss'
import { Carousel } from 'react-bootstrap'

export default function index() {
    return (
        <>
            {/* <PageCreator imageName={specialImage} opacityLevel="0.85" width="40vw">
        <FadeOnUp duration='2s'>
        <p className='mainFont' style={{fontSize: '1.6em'}}>Paju Meal</p>
        <p className='mainFont' style={{fontSize: '0.85em'}}>45 dollars per person (Please call <a style={{textDecoration: 'none', color: '#000'}} href='tel:206-829-8215'>(206) 829-8215</a> two days in advance to order)</p>
        <hr />
        <p className='mainFont' style={{fontSize: '1em'}}>
            - Charcoal grilled 8 oz Short Ribs<br />
            - One Short Rib burger<br />
            - Beet salad<br /> 
            - Lettuce and herb Bouquet<br />
            - Pickled Daikon<br />
            - Roasted Winter Root Vegetables<br />
            - Truffle Mash Potato<br />   
            - SsamJang Sauce</p>
       </FadeOnUp>
    </PageCreator> */}
            {/* <div style={{backgroundColor: 'rgba(255,255,255,0.8)', height: '20vh', width:'100vw'}}>

<p>
Paju Meal
</p>

</div> */}

            <div>
                <p className='mainFont' style={{fontSize: '1.25em', textAlign: 'center', color: '#555555'}}>
                Special Paju New Year Meal
                </p>
                <p className='mainFont' style={{fontSize: '1em', textAlign: 'center', color: '#555555'}}>
                For two $100. Please call <a style={{ textDecoration: 'none', color: '#555555'}} href='tel:206-829-8215'>(206) 829-8215 </a>two days in advance to order.
                </p>
                <p className='mainFont specialSubFont' style={{fontSize: '0.8em', textAlign: 'center', color: '#555555'}}>
                &#8226; Seaweed Cracker &#8226; Yellowtail &#8226; Mushroom &#8226; Dumpling &#8226; Steak
                </p>
                
            </div>

            {/* <div className='menuSpecial'>
                <p className='mainFont' style={{ fontSize: '1.6em' }}>Paju Meal</p>
                <p className='mainFont' style={{ fontSize: '0.85em' }}>For two $100 (Please call <a style={{ textDecoration: 'none', color: '#fff'}} href='tel:206-829-8215'>(206) 829-8215</a> two days in advance to order)</p>
                <hr />
                <p className='mainFont' style={{ fontSize: '1em' }}>
                Seaweed Cracker<br />
                    <p className='mainFont' style={{ fontSize: '0.65em'}}>
                    Galbi Marinated, Asparagus Kimchi
                    </p>
                </p>
                <p className='mainFont' style={{ fontSize: '1em' }}>
                    Yellowtail (raw)<br />
                    <p className='mainFont' style={{ fontSize: '0.65em'}}>
                    Quinoa, Avocado, Salmon Roe
                    </p>
                </p>
                <p className='mainFont' style={{ fontSize: '1em' }}>
                Mushroom<br />
                    <p className='mainFont' style={{ fontSize: '0.65em'}}>
                    White Kimchi, Truffle Aioli, Parmigiano
                    </p>
                </p>
                <p className='mainFont' style={{ fontSize: '1em' }}>
                Dumpling<br />
                    <p className='mainFont' style={{ fontSize: '0.65em'}}>
                    Vegetarian Dumpling, Nasturtium, Radish Broths
                    </p>
                </p>
                <p className='mainFont' style={{ fontSize: '1em' }}>
                Steak<br />
                    <p className='mainFont' style={{ fontSize: '0.65em'}}>
                    Skirt Steak, Farro, Seaweed Jus
                    </p>
                </p>
            </div> */}
            <Carousel nextIcon={<img src={rightChevron} />} prevIcon={<img src={leftChevron} />}>


                <Carousel.Item>
                    <div style={{ background: `url(${oImage1})`, backgroundSize: 'cover', minHeight: '90vh', backgroundPosition: '50% 40%' }}>
                        {/* <div className='galleryPosition'>
                            <FadeOnUpChar className='mainFont' text='Happy New Years' style={{ color: '#eee', fontSize: '2em', textShadow: '2px 2px 5px #000' }} />
                            <br />
                            <FadeOnUpChar className='mainFont' text='Yellowtail, Mushroom, Dumpling, Steak, and Seaweed Cracker dishes' style={{ color: '#eee', fontSize: '1em', textShadow: '2px 2px 5px #000' }} />
                        </div> */}
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div style={{ background: `url(${oImage2})`,  backgroundSize: 'cover', minHeight: '90vh', backgroundPosition: '50% 40%'}}>
                        <div className='galleryPosition'>
                            <FadeOnUpChar className='mainFont' text='Mushroom' style={{ color: '#555555', fontSize: '.8em' }} />
                            <br />
                            <FadeOnUpChar className='mainFont' text='White Kimchi, Truffle Aioli, Parmigiano' style={{ color: '#555555', fontSize: '.6em', padding: '0' }} />
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div style={{ background: `url(${oImage3})`, backgroundSize: 'cover', minHeight: '90vh', backgroundPosition: '50% 40%' }}>
                        <div className='galleryPosition'>
                            <FadeOnUpChar className='mainFont' text='Steak' style={{ color: '#555555', fontSize: '.8em' }} />
                            <br />
                            <FadeOnUpChar className='mainFont' text='Skirt Steak, Farro, Seaweed Jus' style={{ color: '#555555', fontSize: '.6em' }} />
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div style={{ background: `url(${oImage4})`, backgroundSize: 'cover', minHeight: '90vh', backgroundPosition: '50% 40%' }}>
                        <div className='galleryPosition'>
                            <FadeOnUpChar className='mainFont' text='Yellowtail' style={{ color: '#555555', fontSize: '.8em' }} />
                            <br />
                            <FadeOnUpChar className='mainFont' text='Quinoa, Avacado, Salmon Roe' style={{ color: '#555555', fontSize: '.6em' }} />
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div style={{ background: `url(${oImage5})`, backgroundSize: 'cover', minHeight: '90vh', backgroundPosition: '50% 40%' }}>
                        <div className='galleryPosition'>
                            <FadeOnUpChar className='mainFont' text='Dumpling' style={{ color: '#555555', fontSize: '.8em' }} />
                            <br />
                            <FadeOnUpChar className='mainFont' text='Vegetarian Dumpling, Nasturium, Radish Broths' style={{ color: '#555555', fontSize: '.6em' }} />
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div style={{ background: `url(${oImage6})`, backgroundSize: 'cover', minHeight: '90vh', backgroundPosition: '50% 40%' }}>
                        <div className='galleryPosition'>
                            <FadeOnUpChar className='mainFont' text='Seaweed Cracker' style={{ color: '#555555', fontSize: '.8em' }} />
                            <br />
                            {/* <FadeOnUpChar className='mainFont' text='Vegetarian Dumpling, Nasturium, Radish Broths' style={{ color: '#eee', fontSize: '.6em', textShadow: '2px 2px 5px #000' }} /> */}
                        </div>
                    </div>
                </Carousel.Item>

            </Carousel>

        </>
    )
}
