import React from 'react'
import PageCreator from '../../components/PageCreator'
import seattleImage2 from '../../assets/images/seattle2.jpg'
import {Row, Col} from 'react-bootstrap'
import '../../assets/scss/index.scss'

export default function () {
    return (
        <div>
            <PageCreator imageName={seattleImage2} opacityLevel="0.9">
                <Row>
                <Col md={6} xs={12}>
                    <p className='mainFont' style={{fontSize: '1.3em'}}>Paju Korean Cuisine</p><hr />
                    <p className='mainFont' style={{fontSize: '1.2em'}}>513 Westlake Avenue North <br/>Seattle, WA 98109</p>
                    <p className='mainFont' style={{fontSize: '1.2em'}}>(206) 829-8215</p>
                </Col>
                <Col md={6} xs={12}>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2689.118385747621!2d-122.34125372291142!3d47.62382997119145!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54901539cded5849%3A0x9af348a96f2b6227!2s513%20Westlake%20Ave%20N%2C%20Seattle%2C%20WA%2098109!5e0!3m2!1sen!2sus!4v1717262237487!5m2!1sen!2sus"
                    style={{
                    width: '35vw', minWidth:'250px', maxWidth:'500px', minHeight:'35vh', frameborder:"0", allowfullscreen:"", ariaHidden:"false", tabindex:"0"
                    }}></iframe>
                   
                </Col>
                </Row>
            </PageCreator>
        </div>
    )
}
