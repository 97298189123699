import React, {useEffect, useState} from 'react'
import CompleteOrders from './completeOrders.js'
import Pagination from './Pagination'
import PageCreator from '../PageCreator'
import completedOrdersImage from '../../assets/images/completed_orders.jpg'
import socketIOClient from 'socket.io-client';
import API from '../../utils/API'

export default function CompletedOrders() {
    const [orderInfo, setOrderInfo] = useState([]);
    const endpoint = "http://localhost:3001";
    const socket = socketIOClient(endpoint);
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [postsPerPage] = useState(10)

    const indexOfLastPost = currentPage * postsPerPage
    const indexOfFirstPost = indexOfLastPost - postsPerPage
    const currentPosts = orderInfo.slice(indexOfFirstPost, indexOfLastPost);

    const paginate = (pageNumber) => setCurrentPage(pageNumber)

    socket.on('completedOrderChangeEvent', async () =>{
        try{
        await API.getCompletedOrders().then(
                function (response) {
                setOrderInfo(response.data)
                })
        }
        catch(error){
            console.log(error)
        }
    })

    useEffect(() => {
        const fetchOrders = async () => {
            setLoading(true)
            const res = await API.getCompletedOrders().then(
                function (response) {
                    setOrderInfo(response.data)
                    setLoading(false)
                })
        }
        fetchOrders();
     
    }, []);
    return (
        <PageCreator imageName={completedOrdersImage} opacityLevel='0.95' width='100vw'>
            <p className='mainFont' style={{ fontSize: '1.2em' }}>Completed Orders</p>
            <Pagination postsPerPage={postsPerPage} totalPosts = {orderInfo.length} paginate={paginate} />
            <CompleteOrders orders = {currentPosts}/>
        </PageCreator>
    )
}
