import React, {useState} from 'react'
import PageCreator from '../../components/PageCreator'
import menuCreatorImage from '../../assets/images/menuCreator.jpg'
import { Form, Button } from 'react-bootstrap'
import '../../assets/scss/index.scss'
import API from '../../utils/API'
import history from '../History'
export default function MenuCreator() {
    const [storeName, setStoreName] = useState('')
    const [storeDescription, setStoreDescription] = useState('')
    const [storeItemPrice, setStoreItemPrice] = useState('')
    const [storeImageURL, setStoreImageURL] = useState('')
   
    const createStoreItem = event => {
        event.preventDefault();
        API.submitStoreItem({
            storeName: storeName,
            storeDescription: storeDescription,
            storeItemPrice: storeItemPrice,
            storeImageURL: storeImageURL
        }).then(
            history.push('/Store'),
            setStoreName(''),
            setStoreDescription(''),
            setStoreItemPrice('')
        )

    }
    return (
        <PageCreator imageName={menuCreatorImage} opacityLevel='0.9'>
            <p className='mainFont' style={{ fontSize: '1.2em' }}>Store Item Creator</p>
            <hr />
            <Form>
                <Form.Group controlId="title">
                    <Form.Label>Store Item Name</Form.Label>
                    <Form.Control required type="text" placeholder="Enter Menu Name" onChange={event => setStoreName(event.target.value)}/>
                    <Form.Text className="text-muted">
                </Form.Text>
                </Form.Group>
                <Form.Group controlId="menuDescription">
                    <Form.Label>Store Item Description</Form.Label>
                    <Form.Control required type="text" placeholder="Menu Description" onChange={event => setStoreDescription(event.target.value)}/>
                </Form.Group>
                <Form.Group controlId="menuPrice">
                    <Form.Label>Store Item Price</Form.Label>
                    <Form.Control required type="number" placeholder="Menu Item Price" onChange={event => setStoreItemPrice(event.target.value)}/>
                </Form.Group>
                <Form.Group controlId="menuImageURL">
                    <Form.Label>Store Item Image URL</Form.Label>
                    <Form.Control required type="text" placeholder="Image URL" onChange={event => setStoreImageURL(event.target.value)}/>
                </Form.Group>
            
                <Button style = {{float: 'right'}} variant="outline-dark" type="submit" onClick={createStoreItem}>
                    Add to Store
                </Button>
                <br />
                <br />
            </Form>

        </PageCreator>
    )
}
