import React from 'react'
import MainNavbar from '../Navbar'
import Footer from '../Footer'


export default function index(props) {
    return (
      <div>
        <MainNavbar />
        {props.children}
        <Footer></Footer>  
      </div>
    )
}
