import React from 'react'
import PageCreator from '../../components/PageCreator'
import accoladeImage from '../../assets/images/accolades.jpg'
import '../../assets/scss/index.scss'

export default function index() {
    return (
        <PageCreator imageName={accoladeImage} opacityLevel="0.9">
            <p className='mainFont' style={{fontSize: '1.6em'}}>Accolades</p>
            <hr/>
            <p className='mainFont' style={{fontSize: '1em'}}>"Seattle needs more restaurants like Paju: more fascinating risks taken, more luring to explore. The spirit of adventure here and the height of the quality, at these prices, feels like a throwback to when the city was rife with DIY possibility." <br /><br /> -Seattle Times</p>
            <hr />
            <p className='mainFont' style={{fontSize: '1em'}}>"Lower Queen Anne might seem like the least likely spot to find innovative Korean cuisine, but Paju is perfecting the craft."<br/><br/>-Eater Seattle</p>
            <hr />
            <p className='mainFont' style={{fontSize: '1em'}}>"The idea was to order the entire compact menu at Paju and share it with the table, but everyone felt possessive of the bowl of fried rice — black with squid ink, beautifully chewy, with a gentle smokiness and a soft, lingering flavor of kimchi. The dining room is plain, but Bill Soo Jeong’s modern Korean dishes are elegant and expressive, and you won’t want to miss a single one."<br/><br/> - New York Times</p>
            <hr />
            <p className='mainFont' style={{fontSize:'1em'}}>"Paju’s food makes us feel like we’re living in a country ballad - suddenly, it’s easy to love again, everything pairs well with a cold beer, and we can’t help but get emotional over the small details. Things like bulgogi with crispy quinoa and a subtle hint of truffle oil, and squid ink fried rice topped with bacon and a smoked quail egg that we daydream about whenever we hear Shania Twain’s, “You’re Still The One.” Paju doesn’t need any flashy decorations in the dining room or cocktails in ritzy glassware to keep us coming back - they just need to keep making phenomenal food." <br/><br/> - The Infatuation</p>
        </PageCreator>
    )
}
