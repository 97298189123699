import React, { useState, useEffect } from 'react'
import PageCreator from '../../components/PageCreator'
import '../../assets/scss/index.scss'
import ordersImage from '../../assets/images/orders.jpg'
import API from '../../utils/API'
import socketIOClient from 'socket.io-client';
import { Col, Row, Card, Button, Spinner } from 'react-bootstrap';
import history from '../History';


export default function () {
    const [orderInfo, setOrderInfo] = useState([]);
    const [loading, setLoading] = useState(false);

    // const endpoint = "http://localhost:3001";
    // const endpoint = "https://www.pajurestaurant.com:3001";
    const endpoint = "/"
    const socket = socketIOClient(endpoint);
    
    function getOrders() {
        API.getOrders().then(
            function (response) {
                setOrderInfo(response.data)
            })
            
    }

    
    const completeOrder = async (event, id) => {
        event.preventDefault()
        setLoading(true)
        API.getOrder(id).then(
            async(response) => {
            console.log(response.data)
            try {
                await API.completeOrder({
                    name: response.data.name,
                    email: response.data.email,
                    phone: response.data.phone,
                    date: response.data.date,
                    pickUpTime: response.data.pickUpTime,
                    order: response.data.order,
                    status: response.data.status,
                    total: response.data.total,
                    paymentID: response.data.paymentID
                }).then(
                await API.sendEmail({
                    email: response.data.email,
                    title: "Your Order is Ready for Pickup!",
                    emailText: `Your order is now ready ${response.data.name}`,
                    htmlText:`
                    <h1>Paju Order</h1><hr />
                    <h2>Thank you ${response.data.name} for your purchase.</h2>`
    
                }),
                await API.sendTextMessage({
                    recipient: response.data.phone,
                    text: `
                    Paju: Thank you ${response.data.name} for your purchase. Your order is ready for pickup`
                })
                )
                await API.deleteOrder(id)
                .then(console.log('deleted order'))
                .catch(error => console.log(error))
    
                API.getOrders().then(
                    function (response) {
                        setOrderInfo(response.data)
                })
                setLoading(false)
                history.push('/Orders')
                
            }
            catch(error){
                console.log('ERROR' + error)
    
            }
            }
        )

        // try {
        //     await API.deleteOrder(id)
        //     .then(console.log('deleted order'))
        //     .catch(error => console.log(error))

        //     API.getOrders().then(
        //         function (response) {
        //             setOrderInfo(response.data)
        //     })
        //     setLoading(false)
        //     history.push('/Orders')
        // }
        // catch(error){
        //         console.log('THIS IS THE ERROR!', error)
        // }
  
    }


    socket.on('orderChangeEvent', async () =>{
        try{
        await API.getOrders().then(
                function (response) {
                setOrderInfo(response.data)
                })
        }
        catch(error){
            console.log('THIS IS THE ERROR!', error)
        }
    })

    useEffect(() => {
        getOrders()
    }, []);
    return (
        <PageCreator imageName={ordersImage} opacityLevel='0.95'>
            <p className='mainFont' style={{ fontSize: '1.2em' }}>Orders {loading ?  <><p>loading</p><Spinner animation="border" /></>: ''}</p>
            <hr />
            <Row>
            {orderInfo.map((item, i) => {
                i=i+1;
                return(
             
                    <Col id={item._id} key={i} lg={3} sm={3}>
                        <Card style={{marginBottom: '20px'}}>
                            <Card.Body>
                                <Card.Title>Order {i} </Card.Title>
                                <hr />
                                <Card.Text className='mainFont' style = {{fontSize: '0.75em'}}>
                                    Name: {item.name}<br />
                                    Email: {item.email}<br />
                                    Phone: {item.phone}<br />
                                    Date: {item.date}<br />
                                    Pickup Time: {item.pickUpTime}<br />
                              
                                    {Object.keys(item.order).map((sub, i)=>(
                              
                                       <li value={sub} key={i}>{item.order[sub].title} - {item.order[sub].quantity}</li>
                                   
                                    ))}
                                   
                                </Card.Text>
                                
                                <Button variant="outline-dark" onClick={(event)=> completeOrder(event, item._id)} disabled={loading}>Order Completed</Button>
                        
                            </Card.Body>
                        </Card>
                    </Col>
                    
                )
            })}
           </Row>
        </PageCreator>
    )
}
