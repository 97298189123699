import React, {useState, useContext} from 'react'
import PageCreator from '../PageCreator'
import { useHistory } from 'react-router-dom';
import API from '../../utils/API'
import { Button, Form } from 'react-bootstrap';
import { UserContext } from '../../UserContext';
import loginImage from '../../assets/images/entrance.jpg'

export default function () {

        const { setLoggedIn } = useContext(UserContext);
        const { setIsAdmin } = useContext(UserContext);
        const { setUserInfo } = useContext(UserContext)
        const [username, setUsername] = useState('');
        const [password, setPassword] = useState('');
        const [errorMessage, setErrorMessage] = useState('');
        const history = useHistory();
    
        const login = event => {
            event.preventDefault();
            API.authenticateUser({
                username: username,
                password: password
            }).then(
                function (response) {
                    console.log(response.data.loggedIn)
                    if (response.data.role === "user") {
                        setLoggedIn(true)
                        setUserInfo(response.data)
                        history.push('/Welcome')
                    }
                    else if (response.data.role === "administrator"){
                        setIsAdmin(true)
                        setLoggedIn(true)
                        setUserInfo(response.data)
                        history.push('/Welcome')
                    }
                    else if (response === null) {
                        setErrorMessage('username or password incorrect')
                    }
                    else {
                        setErrorMessage('username or password incorrect')
                    }
                }
            )
    
        }
    
    return (
        <PageCreator imageName={loginImage} opacityLevel="0.9" width='75vw'>
        <Form>
    
                <Form.Group controlId="formBasicUsername">
                    <Form.Label>Username</Form.Label>
                    <Form.Control required className="form-control mr-sm-2" type="username" placeholder="Username" aria-label="Username" value={username} onChange={event => setUsername(event.target.value)}  />
                    <Form.Text className="text-muted">
                        {errorMessage}
                    </Form.Text>
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control required className="form-control mr-sm-2" type="password" placeholder="Password" aria-label="Password" value={password} onChange={event => setPassword(event.target.value)} />
                    <Form.Text className="text-muted">
                        {errorMessage}
                    </Form.Text>
                </Form.Group>
       
            <Button style={{float:'right'}} variant="outline-dark" type="submit" onClick={login}>
                Submit
            </Button>
   
        </Form>
        </PageCreator>
    )
}
