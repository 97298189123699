import React, {useEffect, useState} from 'react'
import PageCreator from '../PageCreator'

import * as Yup from 'yup'
import {Formik} from 'formik'
import history from '../History'

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction"; 
import timeGridPlugin from '@fullcalendar/timegrid'
import {Button, Form, Col} from 'react-bootstrap'

import API from '../../utils/API.js'

import socketIOClient from 'socket.io-client';

export default function () {

    const [reservations, setReservations] = useState([])

    const [message, setMessage] = useState('')

    // const endpoint = "http://localhost:3001";
    const endpoint = "/";
    const socket = socketIOClient(endpoint);

    var reser = []

    Object.keys(reservations).map((item) => (
        reser.push({
            start: reservations[item].reservationDate + 'T' + reservations[item].reservationTime,
            title: reservations[item].table + ' for ' + reservations[item].firstName + ' ' + reservations[item].lastName + ' party of ' + reservations[item].peopleCount + ' phone number ' + reservations[item].phoneNumber,
            description: reservations[item].firstName + reservations[item].lastName
        })        
    ))

    socket.on('reservationChangeEvent', async () =>{
        try{
        await API.getReservations().then(
                function (response) {
                setReservations(response.data)
                })
        }
        catch(error){
            console.log('THIS IS THE ERROR!', error)
        }
    })

    useEffect(() => {
        API.getReservations().then(
            function(response){
                setReservations(response.data)
            }
        )
    }, []);


    return (
      <PageCreator>
         <p className='mainFont' style={{ fontSize: '1.5em' }}>Reservation Management</p>
            <FullCalendar
            headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay'
              }}
            plugins={[ dayGridPlugin, interactionPlugin, timeGridPlugin ]}
            initialView="timeGridDay"
            events={reser}
      /><br /><br />
      {/* <Formik
            initialValues={{
                reservationDate: '',
                reservationTime: ''
        

            }}
            onSubmit={async(data, {setSubmitting})=>{
                setSubmitting(true);
                //make async call
                try {
                    await API.submitReservation(data)
                        .then(response => {
                            setMessage(response.data.message)
                            if(response.data.status !== false){
                                history.push('/ThankYouReservation')
                            }
                    })
                }
                catch{
                }
            }}
            
            validationSchema={
                Yup.object({
                reservationDate: Yup.string()
                    .required('Required'),
   
      

            })}
            
        >
          
            {({ values, isSubmitting, handleChange, handleBlur, handleSubmit, touched, isValid, errors})=>(
                <Form onSubmit={handleSubmit}>
                    <Form.Row>    
                        <Form.Group as={Col} controlId="date">
                        <Form.Label>Date of Closing</Form.Label>
                        <Form.Control 
                            type="date"
                            name='reservationDate'
                            value={values.reservationDate}
                            onChange={handleChange} 
                            onBlur={handleBlur}
                            isValid={touched.reservationDate && !errors.reservationDate}
                            isInvalid={!!errors.reservationDate} 
                        />
                        <p style={{ fontSize: '0.75em', color: 'red', float: 'right', margin: '0'}}>{errors.reservationDate}</p>
                        </Form.Group>
                   
                    
                    </Form.Row>
                    <p className='mainFont' style={{ fontSize: '1em', color: 'red' }}>{message}</p>
                    <Button disabled={isSubmitting} style={{float: 'right'}} variant="outline-dark" type='submit'>Submit</Button>
            </Form>
            )}
        </Formik> */}
    </PageCreator>
    )
}
